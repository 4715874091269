<template>
    <v-container fluid>
        <v-toolbar flat>
            <v-toolbar-title>
                {{ $t('oamjobad/label--editor-title') }}
                -
                {{ $t('oamjobad/action--editor-simple') }} #{{ htmlId }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical />
            <v-spacer />
            <v-tooltip v-if="previewLink" bottom>
                <template #activator="{ on }">
                    <v-btn
                        outlined
                        color="secondary"
                        class="white--text mr-4"
                        x-small
                        fab
                        @click="openPreviewLink(previewLink, 2)"
                        v-on="on"
                    >
                        <v-icon dark> mdi-eye </v-icon>
                    </v-btn>
                </template>
                {{ $t('oamcommon/tooltip--preview-simple') }}
            </v-tooltip>
            <v-tooltip v-if="previewLink" bottom>
                <template #activator="{ on }">
                    <v-btn
                        outlined
                        color="primary"
                        class="white--text"
                        x-small
                        fab
                        @click="openPreviewLink(previewLink, 1)"
                        v-on="on"
                    >
                        <v-icon dark> mdi-eye </v-icon>
                    </v-btn>
                </template>
                {{ $t('oamcommon/tooltip--preview') }}
            </v-tooltip>
            <v-btn color="primary" outlined class="ma-4" @click="close()">
                {{ $t('oamjobad/action--close') }}
            </v-btn>
            <v-btn color="primary" @click="save()">
                {{ $t('oampublication/action--save') }}
            </v-btn>
        </v-toolbar>

        <v-card outlined>
            <v-banner single-line>
                <v-toolbar flat dense>
                    <v-btn color="secondary" small @click="openAdvanced()">
                        {{ $t('oamjobad/action--editor-adv') }}
                    </v-btn>
                    <v-divider class="mx-4" inset vertical />
                </v-toolbar>
            </v-banner>
            <v-card-text>
                <v-row>
                    <v-col class="pb-0 pt-0 col-12">
                        <div id="editor" class="simple"></div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <Loader :loader="loading" />
    </v-container>
</template>

<script>
import Vue from 'vue'
import { router } from '@/router'
import { storageService } from '@/services'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import grapesjs from 'grapesjs/dist/grapes.min.js'
import plugin from '@ostendis/grapesjs-preset-ostendis-simple/dist/grapesjs-preset-ostendis-simple.min.js'

import 'grapesjs/dist/css/grapes.min.css'
import '@ostendis/grapesjs-preset-ostendis-simple/style/tooltip.css'
import canvasStyle from '!!raw-loader!@ostendis/grapesjs-preset-ostendis-simple/style/canvasStyle.css'
import '@ostendis/grapesjs-preset-ostendis-simple/dist/grapesjs-preset-ostendis-simple.css'

import de from 'grapesjs/locale/de'
import it from 'grapesjs/locale/it'
import es from 'grapesjs/locale/es'
import fr from 'grapesjs/locale/fr'
import ch from '@/assets/grapesjs.locale.ch'

//import html from 'raw-loader!@/templates/ostTest.html'

export default {
    data: () => ({
        editor: null,
        loading: true,
        htmlId: -1,
    }),
    computed: {
        ...mapFields('doc', ['currentItem.previewLink']),
    },
    mounted() {
        this.htmlId = this.$route.params.id

        this.setItemId(this.htmlId).then(() => {
            this.initEditor()
        })
    },
    methods: {
        ...mapActions('doc', ['setItemId', 'getItem', 'editItem']),
        ...mapActions('file', ['addCompanyAsset', 'delCompanyAsset']),
        ...mapActions('alert', ['showError']),
        ...mapActions('ofs', ['addOfsFile']),

        async initEditor() {
            this.editor = grapesjs.init({
                container: '#editor',
                height: '1100px',
                fromElement: false,
                assetManager: {
                    multiUpload: 0,
                    autoAdd: 0,
                    dropzone: 1,
                    uploadFile: async (e) => {
                        let files = e.dataTransfer ? e.dataTransfer.files : e.target.files

                        if (files[0]) {
                            let maxFileSize = 3145728 //3MB
                            let toLargeImages = ''

                            if (files[0].size > maxFileSize) {
                                toLargeImages +=
                                    '<li><small>' +
                                    files[0].name +
                                    ': <strong>' +
                                    (files[0].size / 1024 / 1024).toFixed(2) +
                                    ' MB' +
                                    '</strong></small></li>'
                            }

                            if (toLargeImages !== '') {
                                const modal = this.editor.Modal
                                const alertMsg =
                                    "<div id='alert-msg-overlay' data-random='" +
                                    Date.now() +
                                    "' >" +
                                    "<div class='alert-msg'>" +
                                    "<div class='header'><h3><span>!</span>" +
                                    'Warnung' +
                                    '</h3></div>' +
                                    "<div class='content'>" +
                                    'Bilder zu gross. Maximum Grösse:' +
                                    ' <strong>' +
                                    (maxFileSize / 1024 / 1024).toFixed(2) +
                                    ' MB' +
                                    '</strong>' +
                                    "<div class='files'>" +
                                    '<ul>' +
                                    toLargeImages +
                                    '</ul>' +
                                    '</div>' +
                                    "<button class='ok' onclick='document.getElementById(\"alert-msg-overlay\").remove();'>ok</button>" +
                                    '</div>' +
                                    '</div>' +
                                    '</div>'
                                modal.setTitle('Bild auswählen' + alertMsg)
                                return
                            }

                            await this.addOfsFile({
                                file: files[0],
                            })
                                .then(async (res) => {
                                    if (res) {
                                        await this.addCompanyAsset({ guid: res.FileGUID, type: 1 }).catch(() => {
                                            this.showError(this.$t('oamcommon/message--upload-failed'))
                                            this.loading = false
                                        })

                                        let transformedObject = {
                                            name: res.FileDisplayName,
                                            src: res.FilePublicUrl,
                                            type: 'image',
                                            height: parseInt(res.FileImageHeightPx),
                                            width: parseInt(res.FileImageWidthPx),
                                        }

                                        this.editor.AssetManager.add(transformedObject)
                                    } else {
                                        // Do nothing
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                    this.showError(this.$t('oamcommon/message--upload-failed'))
                                })
                        }
                    },
                },
                canvas: {
                    styles: ['https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css'],
                },
                canvasCss: canvasStyle,
                plugins: [plugin],
                pluginsOpts: {
                    [plugin]: {
                        t9n: {
                            openTraits: this.$t('oamjobad/label--gjs-view-parameter-btn'),

                            cmdBtnViewCompLabel: this.$t('oamjobad/label--gjs-view-components'),
                            cmdBtnUndoLabel: this.$t('oamjobad/label--gjs-undo'),
                            cmdBtnRedoLabel: this.$t('oamjobad/label--gjs-redo'),
                            openTmBtnTitle: this.$t('oamjobad/label--gjs-view-parameter-btn'),

                            labelIconTooltip: this.$t('oamjobad/label--gjs-select-icon-tooltip'),
                            labelIconSelectMinus: this.$t('oamjobad/label--gjs-icon-minus'),
                            labelIconSelectCircleSolid: this.$t('oamjobad/label--gjs-icon-circle-solid'),
                            labelIconSelectCircle: this.$t('oamjobad/label--gjs-icon-circle'),
                            labelIconSelectCheck: this.$t('oamjobad/label--gjs-icon-check'),
                            labelIconSelectSquare: this.$t('oamjobad/label--gjs-icon-square'),
                            labelIconSelectArrowRight: this.$t('oamjobad/label--gjs-icon-arrow-right'),
                            labelIconSelectCheckCircle: this.$t('oamjobad/label--gjs-icon-check-circle'),
                            labelIconSelectClock: this.$t('oamjobad/label--gjs-icon-clock'),
                            labelIconSelectPercent: this.$t('oamjobad/label--gjs-icon-percent'),
                            labelIconSelectBuilding: this.$t('oamjobad/label--gjs-icon-building'),
                            labelIconSelectHome: this.$t('oamjobad/label--gjs-icon-home'),
                            labelIconSelectGlobe: this.$t('oamjobad/label--gjs-icon-globe'),
                            labelIconSelectFile: this.$t('oamjobad/label--gjs-icon-file'),
                            labelIconSelectUtensils: this.$t('oamjobad/label--gjs-icon-utensils'),
                            labelIconSelectCalendar: this.$t('oamjobad/label--gjs-icon-calendar'),
                            labelIconSelectHourglass: this.$t('oamjobad/label--gjs-icon-hourglass'),
                            labelIconSelectMapMarker: this.$t('oamjobad/label--gjs-icon-map-marker'),
                            labelIconSelectRoad: this.$t('oamjobad/label--gjs-icon-road'),
                            labelIconSelectCoffee: this.$t('oamjobad/label--gjs-icon-coffee'),
                            labelIconSelectPhone: this.$t('oamjobad/label--gjs-icon-phone'),
                            labelIconSelectEnvelope: this.$t('oamjobad/label--gjs-icon-envelope'),
                            labelIconSelectStar: this.$t('oamjobad/label--gjs-icon-star'),

                            labelScalePercent: this.$t('oamjobad/label--gjs-scale-percent'),
                            labelScaleBarColor: this.$t('oamjobad/label--gjs-scale-color-bar'),
                            labelScaleBgColor: this.$t('oamjobad/label--gjs-scale-color-bg'),

                            traitBlkOstendisTooltip: this.$t('oamjobad/label--gjs-ostendis-trait-tooltip'),
                            traitOstNone: this.$t('oamjobad/label--gjs-ostendis-trait-none'),
                            traitOstOrganizationHeading: this.$t('oamjobad/label--gjs-ostendis-trait-org-heading'),
                            traitOstOrganization: this.$t('oamjobad/label--gjs-ostendis-trait-org-text'),
                            traitOstIntroductionHeading: this.$t('oamjobad/label--gjs-ostendis-trait-intro-heading'),
                            traitOstIntroduction: this.$t('oamjobad/label--gjs-ostendis-trait-intro-text'),
                            traitOstDescriptionHeading: this.$t(
                                'oamjobad/label--gjs-ostendis-trait-description-heading'
                            ),
                            traitOstDescription: this.$t('oamjobad/label--gjs-ostendis-trait-description-text'),
                            traitOstTasksHeading: this.$t('oamjobad/label--gjs-ostendis-trait-tasks-heading'),
                            traitOstTasks: this.$t('oamjobad/label--gjs-ostendis-trait-tasks-text'),
                            traitOstRequirementsHeading: this.$t(
                                'oamjobad/label--gjs-ostendis-trait-requirements-heading'
                            ),
                            traitOstRequirements: this.$t('oamjobad/label--gjs-ostendis-trait-requirements-text'),
                            traitOstBenefitsHeading: this.$t('oamjobad/label--gjs-ostendis-trait-benefits-heading'),
                            traitOstBenefits: this.$t('oamjobad/label--gjs-ostendis-trait-benefits-text'),
                            traitOstContactHeading: this.$t('oamjobad/label--gjs-ostendis-trait-contact-heading'),
                            traitOstContact: this.$t('oamjobad/label--gjs-ostendis-trait-contact-text'),
                            traitOstCallToAction: this.$t('oamjobad/label--gjs-ostendis-trait-cta'),

                            traitOstLogoPicURL: 'Logo Image',
                            traitOstHeaderPic1URL: 'Header Image 1',
                            traitOstHeaderPic2URL: 'Header Image 2',
                            traitOstHeaderPic3URL: 'Header Image 3',
                            traitOstFooterPic1URL: 'Footer Image 1',
                            traitOstFooterPic2URL: 'Footer Image 2',
                            traitOstFooterPic3URL: 'Footer Image 3',
                            traitOstAdditionalPic1URL: 'Additional Image 1',
                            traitOstAdditionalPic2URL: 'Additional Image 2',
                            traitOstAdditionalPic3URL: 'Additional Image 3',
                            traitOstVideoURL: 'Video',

                            facebookBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-fb'),
                            instagramBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-insta'),
                            youtubeBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-youtube'),
                            linkedinBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-li'),
                            xingBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-xing'),
                            twitterBlkLabelSite: this.$t('oamjobad/label--gjs-block-content-tw'),
                            facebookBlkLabelShare: this.$t('oamjobad/label--gjs-block-content-fb-share'),
                            linkedinBlkLabelShare: this.$t('oamjobad/label--gjs-block-content-li-share'),
                            xingBlkLabelShare: this.$t('oamjobad/label--gjs-block-content-xing-share'),
                            twitterBlkLabelShare: this.$t('oamjobad/label--gjs-block-content-tw-share'),
                            whatsAppBlkLabelShare: this.$t('oamjobad/label--gjs-block-content-wa-share'),
                            mailBlkLabel: this.$t('oamjobad/label--gjs-block-content-mail-share'),
                            smsBlkLabel: this.$t('oamjobad/label--gjs-block-content-sms-share'),
                            printBlkLabel: this.$t('oamjobad/label--gjs-block-content-print'),
                            smSitesCategoryLabel: this.$t('oamjobad/gjs-cat-social-link'),
                            smSharesCategoryLabel: this.$t('oamjobad/gjs-cat-social-share'),
                            sharesCategoryLabel: this.$t('oamjobad/gjs-cat-general-share'),
                            traitBlkValue: this.$t('oamjobad/label--gjs-trait-value'),
                            inputRangeBlkLabel: this.$t('oamjobad/label--gjs-block-input-range'),

                            hideInSimpleHtmlLabel: this.$t('oamjobad/label--gjs-trait-simple-html-hide'),
                            hideInSimpleHtmlTooltip: this.$t('oamjobad/label--gjs-trait-simple-html-hide-tooltip'),

                            ostToolbarClone: this.$t('oamjobad/label--gjs-ost-toolbar-clone'),
                            ostToolbarDelete: this.$t('oamjobad/label--gjs-ost-toolbar-delete'),
                            ostToolbarUp: this.$t('oamjobad/label--gjs-ost-toolbar-up'),
                            ostToolbarDown: this.$t('oamjobad/label--gjs-ost-toolbar-down'),
                            cmdRteRemoveFormat: this.$t('oamjobad/label--gjs-rte-remove-format'),

                            cmdBtnDesktopLabel: 'Desktop',
                            cmdBtnTabletLabel: 'Tablet',
                            cmdBtnMobileLabel: 'Mobile',
                        },
                    },
                },
                storageManager: {
                    id: '', // Prefix identifier that will be used on parameters
                    type: 'remote', // Type of the storage
                    autosave: false, // Store data automatically
                    autoload: false, // Autoload stored data on init
                },
                i18n: {
                    locale: 'de', // default locale
                    localeFallback: 'en', // default fallback
                    detectLocale: false, // by default, the editor will detect the language
                    messages: { de, it, es, fr, ch },
                },
                richTextEditor: {
                    actions: ['bold', 'italic', 'underline', 'link'], // command removeFormat
                    onPaste: ({ ev, rte }) => {
                        ev.preventDefault()
                        const { clipboardData } = ev
                        const text = clipboardData.getData('text')
                        rte.exec('insertText', text)
                    },
                },
                domComponents: {
                    draggableComponents: false,
                },
            })

            // Add custom storage to the editor
            const sm = this.editor.StorageManager
            sm.add('ost', {
                load: async () => {
                    return await this.getItem(this.htmlId)
                        .then((data) => {
                            this.loading = false
                            return data
                        })
                        .catch(() => {
                            this.showError(this.$t('oamcommon/message--download-failed'))
                            this.loading = false
                        })
                },

                store: async (data) => {
                    let pageData = {
                        html: this.editor.getHtml(),
                        components: JSON.stringify(this.editor.getComponents()),
                        css: this.editor.getCss(),
                        styles: JSON.stringify(data.styles),
                    }

                    return await this.editItem({ id: this.htmlId, item: pageData })
                        .then(() => {
                            this.loading = false
                        })
                        .catch(() => {
                            this.showError(this.$t('oamcommon/message--upload-failed'))
                            this.loading = false
                        })
                },
            })

            // Extend the original `image`
            this.editor.AssetManager.addType('image', {
                view: {
                    onRemove(e) {
                        e.stopPropagation()
                        const model = this.model
                        confirm(Vue.i18n.translate('oamcommon/message--delete')) && model.collection.remove(model)
                    },
                },
            })

            this.editor.on('asset:remove', async (asset) => {
                let guid = asset.id.substring(asset.id.lastIndexOf('/') + 1)
                this.delCompanyAsset(guid)
            })

            // Add locale
            let code = storageService.get('userLanguage')
            let i18n = this.editor.I18n
            i18n.setLocale(code)

            sm.setCurrent('ost')
            await this.editor.load()
            this.editor.UndoManager.clear()
        },

        save() {
            this.loading = true
            this.editor.store().then(() => {
                router.push({ name: 'ad' })
            })
        },

        openPreviewLink(link, previewType) {
            if (link.indexOf('//') > 0) {
                window.open(link + '?preview=' + previewType)
            } else {
                window.open('//' + link + '?preview=' + previewType)
            }
        },

        openAdvanced() {
            const um = this.editor.UndoManager

            if (um.hasUndo()) {
                this.$confirm(Vue.i18n.translate('oamcommon/message--save'), {
                    title: Vue.i18n.translate('oamcommon/label--warning'),
                    buttonTrueText: Vue.i18n.translate('oamcommon/label--yes'),
                    buttonFalseText: Vue.i18n.translate('oamcommon/label--no'),
                    persistent: true,
                }).then((res) => {
                    if (res) {
                        this.loading = true
                        this.editor.store().then(() => {
                            router.push({ name: 'editor-adv' })
                        })
                    } else {
                        router.push({ name: 'editor-adv' })
                    }
                })
            } else {
                router.push({ name: 'editor-adv' })
            }
        },

        close() {
            router.push({ name: 'ad' })
        },
    },
}
</script>

<style scoped>
@import './../assets/css.css';

#editor {
    height: 0px;
    overflow: visible;
}

.v-banner >>> .v-banner__wrapper {
    padding: 0;
}
</style>
